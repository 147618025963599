import React from "react"
import Layout from "../components/Layout"

const About = ({ location }) => {
  // Page setting
  const lang = "ja"
  const pageTitle = "About"
  const pageSlug = "about"
  const pageKeywords = [`箕澤屋について`, `About`]
  const pageDescription =
    "箕澤屋は信州伊那谷の箕輪町にある築150年の古民家です。"
  const pageImage = ""

  return (
    <>
      <Layout
        lang={lang}
        location={location}
        pageTitle={pageTitle}
        pageSlug={pageSlug}
        pageKeywords={pageKeywords}
        pageDescription={pageDescription}
        pageImage={pageImage}
      >
        <div className="page-title-wrapper container-narrow">
          <h1 className="page-title">{pageTitle}</h1>
        </div>
        <div className="container-narrow main-inner">
          <section></section>
        </div>
      </Layout>
    </>
  )
}

export default About
